body.home {
    background-image: url('homepage-yarn-bowl.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.container.home {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
}

.home main {
    background-color: #fff;
    opacity: .9;
    padding: 3rem;
    width: 90%;
    max-width: 500px;
}